export const GetNameChoose = (state, type, sanh) => {
    if (sanh) {
        if (sanh.indexOf("Xóc đĩa") != -1) {
            let chon = "Chọn ";
            state?.split(" ").map((item) => {
                if (Number(item) == 2 && type == null) {
                    chon += "4 trắng, ";
                }
                if (Number(item) == 1 && type == null) {
                    chon += "4 đỏ, ";
                }
                if (Number(item) == 3 && type == null) {
                    chon += "3 đỏ 1 trắng, ";
                }
                if (Number(item) == 4 && type == null) {
                    chon += "3 trắng 1 đỏ, ";
                }
                if (Number(item) == 5 && type == null) {
                    chon += "Chẵn, ";
                }
                if (Number(item) == 6 && type == null) {
                    chon += "Lẻ, ";
                }
            });
            return chon.substring(0, chon.length - 2);
        }
        if (sanh.indexOf("Tài xỉu") != -1) {
            let chon = "Chọn ";
            state?.split(" ").map((item) => {
                if (Number(item) == 1 && type == null) {
                    chon += "Chẵn, ";
                }
                if (Number(item) == 2 && type == null) {
                    chon += "Lẻ, ";
                }
                if (Number(item) == 3 && type == null) {
                    chon += "Tài, ";
                }
                if (Number(item) == 4 && type == null) {
                    chon += "Xỉu, ";
                }
            });
            return chon.substring(0, chon.length - 2);
        }
    }
    if (type == 1 && state != null) {
        return "Lô 2: " + state;
    }
    if (type == 2 && state != null) {
        return "3 càng ĐB: " + state;
    }
    if (type == 3 && state != null) {
        return "Đề ĐB: " + state;
    }
    if (type == 4 && state != null) {
        return "Lô xiên 2: " + state;
    }
    if (type == 5 && state != null) {
        return "Lô xiên 3: " + state;
    }
    if (type == 6 && state != null) {
        return "Lô xiên 4: " + state;
    }
    if (type == 7 && state != null) {
        return "Trượt xiên 4: " + state;
    }
    if (type == 8 && state != null) {
        return "Trượt xiên 8: " + state;
    }
    if (type == 9 && state != null) {
        return "Lô 2 số đầu: " + state;
    }
    if (type == 10 && state != null) {
        return "Lô 2 số 1K: " + state;
    }
    if (type == 11 && state != null) {
        return "Lô 3 số: " + state;
    }
    if (type == 12 && state != null) {
        return "Lô 4 số: " + state;
    }
    if (type == 13 && state != null) {
        return "Đề đầu ĐB: " + state;
    }
    if (type == 14 && state != null) {
        return "Đề giải 7: " + state;
    }
    if (type == 15 && state != null) {
        return "Đề giải nhất: " + state;
    }
    if (type == 16 && state != null) {
        return "Đề đầu giải nhất: " + state;
    }
    if (type == 17 && state != null) {
        return "Đầu: " + state;
    }
    if (type == 18 && state != null) {
        return "Đuôi: " + state;
    }
    if (type == 19 && state != null) {
        return "3 càng đầu: " + state;
    }
    if (type == 20 && state != null) {
        return "Đề đầu đuôi: " + state;
    }
    if (type == 21 && state != null) {
        return "3 càng đầu đuôi: " + state;
    }
    if (type == 22 && state != null) {
        return "4 càng ĐB: " + state;
    }
    if (type == 23 && state != null) {
        return "Trượt xiên 10: " + state;
    }
    if (type == 24 && state != null) {
        return "3 càng giải nhất: " + state;
    }
};

export const GetCountry = (id) => {
    if (id == "vuta") {
        return "Vũng Tàu";
    } else if (id == "dalat") {
        return "Đà Lạt";
    } else if (id == "kigi") {
        return "Kiên Giang";
    } else if (id == "tigi") {
        return "Tiền Giang";
    } else if (id == "cama") {
        return "Cà Mau";
    } else if (id == "doth") {
        return "Đồng Tháp";
    } else if (id == "tphc") {
        return "TP.HCM";
    } else if (id == "bali") {
        return "Bạc Liêu";
    } else if (id == "betr") {
        return "Bến Tre";
    } else if (id == "cath") {
        return "Cần Thơ";
    } else if (id == "dona") {
        return "Đồng Nai";
    } else if (id == "sotr") {
        return "Sóc Trăng";
    } else if (id == "angi") {
        return "An Giang";
    } else if (id == "bith") {
        return "Bình Thuận";
    } else if (id == "tani") {
        return "Tây Ninh";
    } else if (id == "bidu") {
        return "Bình Dương";
    } else if (id == "trvi") {
        return "Trà Vinh";
    } else if (id == "vilo") {
        return "Vĩnh Long";
    } else if (id == "biph") {
        return "Bình Phước";
    } else if (id == "hagi") {
        return "Hậu Giang";
    } else if (id == "loan") {
        return "Long An";
    } else if (id == "khho") {
        return "Khánh Hòa";
    } else if (id == "kotu") {
        return "Kom Tum";
    } else if (id == "thth") {
        return "Huế";
    } else if (id == "phye") {
        return "Phú Yên";
    } else if (id == "dalak") {
        return "Đăk Lăk";
    } else if (id == "quna") {
        return "Quảng Nam";
    } else if (id == "dana") {
        return "Đà Nẵng";
    } else if (id == "qubi") {
        return "Quảng Bình";
    } else if (id == "bidi") {
        return "Bình Định";
    } else if (id == "qutr") {
        return "Quảng Trị";
    } else if (id == "gila") {
        return "Gia Lai";
    } else if (id == "nith") {
        return "Ninh Thuận";
    } else if (id == "qung") {
        return "Quảng Ngãi";
    } else if (id == "dano") {
        return "Đăk Nông";
    }
};

export const GetImage = (id) => {
    if (id == "vuta") {
        return "vungtau";
    } else if (id == "dalat") {
        return "dalat";
    } else if (id == "kigi") {
        return "kiengiang";
    } else if (id == "tigi") {
        return "tiengiang";
    } else if (id == "cama") {
        return "camau";
    } else if (id == "doth") {
        return "dongthap";
    } else if (id == "tphc") {
        return "tphcm";
    } else if (id == "bali") {
        return "baclieu";
    } else if (id == "betr") {
        return "bentre";
    } else if (id == "cath") {
        return "cantho";
    } else if (id == "dona") {
        return "dongnai";
    } else if (id == "sotr") {
        return "soctrang";
    } else if (id == "angi") {
        return "angiang";
    } else if (id == "bith") {
        return "binhthuan";
    } else if (id == "tani") {
        return "tayninh";
    } else if (id == "bidu") {
        return "binhduong";
    } else if (id == "trvi") {
        return "travinh";
    } else if (id == "vilo") {
        return "vinhlong";
    } else if (id == "biph") {
        return "binhphuoc";
    } else if (id == "hagi") {
        return "haugiang";
    } else if (id == "loan") {
        return "longan";
    } else if (id == "khho") {
        return "khanhhoa";
    } else if (id == "kotu") {
        return "komtum";
    } else if (id == "thth") {
        return "hue";
    } else if (id == "phye") {
        return "phuyen";
    } else if (id == "dalak") {
        return "daklak";
    } else if (id == "quna") {
        return "quangnam";
    } else if (id == "dana") {
        return "danang";
    } else if (id == "qubi") {
        return "quangbinh";
    } else if (id == "bidi") {
        return "binhdinh";
    } else if (id == "qutr") {
        return "quangtri";
    } else if (id == "gila") {
        return "gialai";
    } else if (id == "nith") {
        return "ninhthuan";
    } else if (id == "qung") {
        return "quangngai";
    } else if (id == "dano") {
        return "daknong";
    }
};

export const GetDate = (id) => {
    if (id == "khho" || id == "kotu" || id == "thth" || id == "dalat" || id == "kigi" || id == "tigi") {
        return "Chủ nhật";
    } else if (id == "phye" || id == "thth" || id == "cama" || id == "doth" || id == "tphc") {
        return "Thứ 2";
    } else if (id == "dalak" || id == "quna" || id == "bali" || id == "vuta" || id == "betr") {
        return "Thứ 3";
    } else if (id == "dana" || id == "khho" || id == "cath" || id == "dona" || id == "sotr") {
        return "Thứ 4";
    } else if (id == "qubi" || id == "bidi" || id == "qutr" || id == "angi" || id == "bith" || id == "tani") {
        return "Thứ 5";
    } else if (id == "gila" || id == "nith" || id == "bidu" || id == "trvi" || id == "vilo") {
        return "Thứ 6";
    } else if (id == "dana" || id == "qung" || id == "dano" || id == "biph" || id == "hagi" || id == "loan" || id == "tphc") {
        return "Thứ 7";
    }
};
